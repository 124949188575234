import lodashSet from 'lodash/set'
import { useState, useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { node, string, bool, arrayOf, oneOf, object, shape, number, oneOfType } from 'prop-types'

import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { LaunchDarklyWidget } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.components'
import { PromotionBanner, usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import { useChatAutoPopup } from '@saatva-bits/pattern-library.utils.chat'

import Nav from '@/layouts/Nav'
import Footer from '@/layouts/Footer'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'

import useDeferScripts from '@/hooks/useDeferScripts'
import {
    SCRIPTS
} from '@/utils/headScripts'
import { getDataLayerData } from '@/utils/datalayer'
import { navigationDataType, viewingRoomDataType } from '@/types'

import noSaleCopy from '@/temp-configs/no-sale-copy'

const API_PATH = `${process.env.NEXT_PUBLIC_BASE_PATH}/api`
export default function DefaultLayout({
    children,
    applyDoNotIndex,
    description,
    title,
    // excludedScripts,
    navigationData,
    footerData,
    launchDarklyContextData,
    pageType = '',
    vanityUrlData,
    viewingRoomData,
    displayLDWidget,
    displaySiteConfig,
    customCanonical,
    breadcrumbs,
    locationSchema,
    hideFooter = false,
    mktChannel,
    contactPhoneNumber
}) {
    let promotionData = usePromotionsData()
    
    useDeferScripts()
    useChatAutoPopup(90000)

    const { asPath } = useRouter()
    const [showLDWidget, setShowLDWidget] = useState(false)

    useEffect(() => {
        // Instana pages config
        if (typeof window !== 'undefined') {
            window.ineum?.('page', window.location.pathname)
        }
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setShowLDWidget(displayLDWidget || displaySiteConfig)
        }
    }, [displayLDWidget, displaySiteConfig])

    const { experiments, flagOverrides } = launchDarklyContextData

    // const loadedScripts = getScriptsToLoad(excludedScripts || [])
    const dataLayerData = getDataLayerData(pageType, experiments, flagOverrides)

    const path = customCanonical || asPath.split('#')[0].split('?')[0].replace(/\/$/, '')
    const canonicalUrlPath = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}${path}`

    useEffect(() => {
        const storage = storageWrapper()
        const sessionStorageKey = 'blockPromoPopup'

        try {
            if (storage.getItem(sessionStorageKey) === null) {
                const marketingParams = [
                    'utm_source',
                    'utm_medium',
                    'utm_campaign',
                    'utm_term',
                    'utm_content',
                    'cq_src',
                    'cq_cmp',
                    'gclid',
                    'gclsrc'
                ]
                const urlParams = new URLSearchParams(window.location.search)
                const referredByMarketing = marketingParams.some((param) => urlParams.has(param))
                const isHomepage = window.location.pathname === '/'
                storage.setItem(sessionStorageKey, isHomepage || !referredByMarketing)
            }
        } catch { }
    }, [])

    const { isV2 } = useExperiment('EXP.NO_SALE_CALL.DIS-306')

    if (
        isV2 && 
        (mktChannel === 'direct' || mktChannel === 'organic') &&
        !promotionData.sitewidePromo.promoProfile.isCouponPromo
    ) {
        const v2Copy = noSaleCopy[mktChannel]
        const keys = ['sitewide', 'saleBannerHeading', 'saleBannerSubtitle', 'pdp.default']
        keys.forEach(key => {
            if (key === 'saleBannerSubtitle') {
                // We dont want to repeat the same copy in the subheading so we null it out
                lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.messageDesktop`, null)
                lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.messageMobile`, null)
            } else {
                lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.messageDesktop`, v2Copy)
                lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.messageMobile`, v2Copy)
            }
            // Remove the panel CTAs, leave only the v2 copy
            lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.action`, null)
            lodashSet(promotionData.sitewidePromo.pagesMessaging, `${key}.actionText`, null)
        })
    }
    
    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="format-detection" content="telephone=no"></meta>
                <meta name="description" content={description || 'The highest quality handcrafted mattresses, bedding & furniture at the most comfortable prices.'} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {applyDoNotIndex && <meta name="robots" content="noindex" />}
                <link rel="icon" sizes="64x64" href="/favicon.png" />
                <link rel="icon" type="image/png" sizes="64x64" href="/favicon.png" />
                <link rel="apple-touch-icon" sizes="64x64" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/favicon.png" />
                <meta name="msapplication-TileColor" content="#FFFFFF" />
                <meta name="msapplication-TileImage" content="/favicon.png" />
                <link rel="canonical" href={canonicalUrlPath} />
                <script dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(${JSON.stringify(dataLayerData)});
                    `
                }} />
                {Boolean(locationSchema) && <script type="application/ld+json" dangerouslySetInnerHTML={{
                    __html: locationSchema
                }}></script>}
            </Head>
            <main>
                <PromotionBanner promotionData={promotionData} vanityURLs={vanityUrlData} />
                <Nav navigationData={navigationData} viewingRoomData={viewingRoomData} contactPhoneNumber={contactPhoneNumber}/>
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                {children}
                {!hideFooter && <Footer footerData={footerData} />}
                {showLDWidget &&
                    <LaunchDarklyWidget flagApiUrl={`${API_PATH}/flag-data`} tokenApiUrl={`${API_PATH}/edge-token`} advanced={displaySiteConfig} bypassCacheParam={null} />
                }
            </main>
        </>
    )
}

DefaultLayout.propTypes = {
    children: node.isRequired,
    applyDoNotIndex: bool,
    description: string,
    title: string.isRequired,
    excludedScripts: arrayOf(oneOf(Object.values(SCRIPTS))),
    navigationData: navigationDataType,
    launchDarklyContextData: shape({
        experiments: object,
        featureFlags: object,
        flagOverrides: object
    }),
    cartData: shape({
        cartQuantity: oneOfType([string, number]),
        cartSubtotal: oneOfType([string, number])
    }),
    pageType: string,
    viewingRoomData: viewingRoomDataType,
    displayLDWidget: bool,
    displaySiteConfig: bool,
    hideFooter: bool,
    /** Default is direct, set in buildServerSideProps */
    mktChannel: string,
    contactPhoneNumber: string
}
