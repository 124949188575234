import { useState, useEffect, useCallback } from 'react'

import { useSharedCartService } from '@saatva-bits/pattern-library.modules.cart'
import { MastheadSiteNav } from '@saatva-bits/pattern-library.modules.navigation'
import { CartFlyout } from '@saatva-bits/pattern-library.modules.cart-flyout'
import {
    SearchBar,
    clientSearch,
    clientSuggestionsSearch,
    supportSearch
} from '@saatva-bits/pattern-library.components.search-bar'
import { UserAccountPopup } from '@saatva-bits/pattern-library.components.user-account-popup'

import { Cookies } from '@saatva-bits/pattern-library.utils.cookies'
import { open as openChat } from '@saatva-bits/pattern-library.utils.chat'
import { useExperiment, useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly'

import { fetchApi } from '@/clientRequests/api'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL
const ALGOLIA_SEARCH_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX
const ALGOLIA_ARTICLE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_ARTICLE_INDEX
const SEARCH_INDICES = [ALGOLIA_SEARCH_INDEX, ALGOLIA_ARTICLE_INDEX]

export default function Nav({ navigationData, viewingRoomData, contactPhoneNumber }) {
    const cartContext = useSharedCartService()
    const [searchOpen, setSearchOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    const { isV1: hideMattressSubcategoriesNav } = useExperiment('EXP.DT_NAV_REMOVE_SUBCAT.DIS-146')
    const { isOn: ENABLE_NAV_USER_ACCOUNTS } = useFeatureFlag('ENABLE_USER_ACCOUNTS_NAV.CONV-59')

    const toggleSearchBar = () => setSearchOpen(prev => !prev)

    /* If hideVrIcon is true, icon will be completely hidden, allowing the LD flag to override the display completely */
    const hideVrIcon = viewingRoomData.hideVrIcon
    const [viewingRoomUrl, setViewingRoomUrl] = useState(viewingRoomData.url)

    const updateVrIcon = async () => {
        /* Hit designated (shared) API for viewing room response */
        const { url } = await fetchApi('viewing-room')
        /* Update viewing room url to display icon if valid location */
        setViewingRoomUrl(url)
    }

    const searchAnalyticsCallback = useCallback(() => {
        const gaCookieValue = Cookies.get('_ga')
        // Removes prefix and converts periods to underscores
        const gaUserIdFromCookie = gaCookieValue?.split('.')?.slice(-2)?.join('_')
        if (typeof window !== 'undefined') {
            window.dataLayer.push({
                event: 'Hits Viewed',
                algoliaUserToken: gaUserIdFromCookie
            })
        }
    }, [])

    useEffect(() => {
        /* Only check/update VR location if there isn't a vrKey value, indicating
        the cookie hasn't already been set. */
        if (typeof window !== 'undefined' && !viewingRoomData.vrKey && ('url' in viewingRoomData)) {
            updateVrIcon().catch(error => console.log(error))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const searchProps = {
        isSearchOpen: searchOpen,
        isMenuOpen: menuOpen,
        onClose: toggleSearchBar,
        searchCallback: clientSearch,
        searchIndices: SEARCH_INDICES,
        suggestionsSearchCallback: clientSuggestionsSearch,
        supportSearchCallback: supportSearch,
        analyticsCallback: searchAnalyticsCallback
    }

    const saatvaDomain = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}`
    const userAccountPopup = ENABLE_NAV_USER_ACCOUNTS && <UserAccountPopup saatvaDomain={saatvaDomain} />

    const navProps = {
        viewingRoomUrl: viewingRoomUrl,
        hideVrIcon: hideVrIcon,
        handleChat: openChat,
        topLevelItems: navigationData,
        imgixDomain: IMGIX_URL,
        onClickSearchButton: toggleSearchBar,
        onMenuToggle: setMenuOpen,
        hideOrderTracker: false,
        hideCart: false,
        hideMattressesSubcategoriesDesktop: hideMattressSubcategoriesNav,
        cartContext,
        cartFlyoutDeps: { CartFlyout },
        userAccountPopup,
        contactPhoneNumber: contactPhoneNumber
    }

    return (
        <header className={'headerClasses'} id="sticky-header">
            <div>
                <MastheadSiteNav {...navProps}>
                    <SearchBar {...searchProps} />
                </MastheadSiteNav>
            </div>
        </header>
    )
}
